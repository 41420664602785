.user-left {
  flex-grow: 1;
  flex-shrink: 0;
  /* max-width: 540px; */
  width: 28%;
  height: 100%;
  background-color: #fff;
  /* min-height: 750px; */
  border-radius: 5px;
}

.input {
  margin: 0;
  width: 100%;
}

.big-input {
  width: 90%;
  background: #F5F5F5;
  border-radius: 50px;
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
.name {
  width: 100%;
  padding: 20px;
  height: 40px;
}

.red-border {
  border: 1px solid #f26363;
}

.user-right {
  lex-grow: 1;
  flex-shrink: 0;
  /*max-width: 993px;*/
  width: 70%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  /*min-height: 750px;*/
}
.user-photo {
  position: relative;
  width: 100%;
  height: 260px;
  border-top-left-radius: 5px;
  /* aspect-ratio: 1.49; */
  background: url("../../assets/imgs/user-pic-placeholder.jpg") no-repeat center /
    cover;
}
.user-photo.uploaded {
  background: none;
}
.user-info {
  font-size: 20px;
  font-weight: bold;
  padding: 5px 4px;
  border-bottom: 1px  solid #e2e2e2;
  text-align: center;
}
.user-description {
  padding: 25px 30px;
}
.user-name {
  font-size: 24px;
  font-weight: 500;
}
.user-role {
  font-size: 18px;
  margin-bottom: 50px;
}
.user-contacts {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}
.user-contacts + .user-contacts {
  margin-top: 15px;
}
.user-right-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}
.user-additional {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.user-header-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}
.user-edit-btn {
  width: 140px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e2e2e2;
}
.user-edit-btn.link {
  background-color: #f6f6f6;
  border: 1px solid #e2e2e2;
  color: #000;
}
.user-edit-btn.save {
  background-color: #6ebf46;
  color: #fff;
}
.user-additional-content {
  /*display: flex;*/
  flex-direction: column;
  padding: 0 100px;
}
.user-detail {
  font-weight: 400;
  color: #000;
  font-size: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.school-other .user-detail-input-change {
  width: 100%!important;
}
.school-other .error_zone {
  padding: 4px 0px;
  margin: 0 !important;
  padding: 4px 0px;
  position: relative;
  width: 640px;
  height: 100%;
  background: #F5F5F5;
  border-radius: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 65% !important;
}
.passwords-detail {
  margin-top: 0 !important;
  flex-direction: column !important;
  align-items: start !important;
}
.phone {
  border-radius: 30px !important;
  width: 100% !important;
  height: 52px !important;
}
.black {
  color: #000;
  border-left: 1px solid #CACACA;
}
.email-phone{
  font-weight: 400;
  color: #000;
  font-size: 16px;
  width: 58%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.user-input {
  position: relative;
  width: 640px;
  height: 100%;
  background: #F5F5F5;
  border-radius: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.user-input-pass {
  width: 240px !important;
}

.user-input.select {
  position: relative;
  width: 640px;
  height: 100%;
  border: none;
  border-radius: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.user-input .react-datepicker__input-container input {
  width: 130px;
  text-align: left;
  height: 30px;
}
.user-input.select > .select {
  width: 100%;
  height: 100%;
}
.phoneContainer {
  border-radius: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.flagButton {

}
.phone-input {
  border-radius: 50px !important;
  background-color: #F5F5F5 !important;
  width: 100% !important;
  height: 40px !important;
  padding-left: 57px !important;
  border: 1px solid #fff !important;
  color: #000 !important;
  font-size: 16px !important;
  line-height: 1.5;
  font-weight: 300;
  font-family: 'Noto Sans Armenian', sans-serif;
}
.account-phoneNumber-input {
  border-left: 1px solid #fff;
  padding: 0 90px 0 65px;
  width: 90%;
  height: 40px;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  margin-top: -12px;
  z-index: 2;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.popup-button-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 40px 0 30px;
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  min-width: 700px;
  height: 400px;
  border-radius: 5px;
}

.popup-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.phone-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: -38px;
  z-index: 3;
}

.account-phoneNumber-input-error {
  text-align: center;
  border-left: 1px solid #f26363;
  padding: 0 90px 0 0;
  width: 75%;
  height: 52px;
  color: #000;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  margin-top: -100px;
  margin-bottom: 40px;
  z-index: 2;
}
.phoneNumberContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.account-phoneNumber-input::placeholder {
  padding-left: 35px;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  text-align: start;
}
.account-phoneNumber-input-error::placeholder {
  padding-left: 35px;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  text-align: start;
}
.account-phoneNumber-input:focus::placeholder {
  opacity: 0;
}
.account-phoneNumber-input-error:focus::placeholder {
  opacity: 0;
}
.phone-input-error {
  border-radius: 50px !important;
  background-color: rgba(217, 217, 217, 0.25) !important;
  width: 100% !important;
  height: 52px !important;
  padding-left: 57px !important;
  border: 1px solid #f26363 !important;
  color: #000;
  font-size: 16px !important;
  line-height: 1.5;
  font-weight: 300;
  font-family: 'Noto Sans Armenian', sans-serif;
}
.user-input.border-none {
  border: none;
  background: transparent;
  /*text-align: right;*/
}
.user-input > svg {
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.phone-email-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  margin-right: 5px;
}
.phone-email-container span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-container {
  background: #F5F5F5;
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  padding: 20px;
}
.email-container img {
  width: 17px;
  height: 17px
}
.user-input select {
  border: none;
  display: block;
  background: red;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.black-input {
  background: transparent;
  border: 1px solid #000;
  color: #000;
  align-self: center;
}
.user-input select:focus {
  outline: none;
}
.error-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.user-form-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin: 40px 0;
}
.notVerified {
  display: flex;
  width: 58%;
  justify-content: flex-end;
  color: #F00;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}
.verified {
  color: #42D3B0;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
}
.edit_button{
  margin-top: -10px;
}

.edit {
  cursor: pointer;
  margin-right: 12px;
}

.user-form-btn {
  width: 126px;
  height: 32px;
  border-radius: 15px;
  font-size: 16px;
  background-color: #F5F5F5;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-form-btn.save {
  background-color: #42D3B0;
  color: #FFFFFF;
}
.user-form-btn.link {
  background-color: #F00;
  color: #FFFFFF;
}
.user-pass {
  display: flex;
  padding: 20px;
  justify-content: center;
}
.user-pass-text {
  font-size: 22px;
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
  padding-left: 40px;
  padding-bottom: 10px;
}
.user-action-box{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.user-pass-btn {
  font-size: 18px;
  width: 300px;
  height: 46px;
  border-radius: 23px;
  background: #42D3B0;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-delete-btn {
  font-size: 18px;
  width: 300px;
  height: 46px;
  border-radius: 23px;
  background: #FF0000;
  color: #FFFFFF;
  text-transform: uppercase;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-pass form {
  width: 55%;
}
.account-info .user-input,
.user-pass .user-input {
  width: unset;
  margin-left: unset;
}
.user-pass input {
  width: 100%;
  height: 100%;
}
.user-pass input:focus {
  outline: none;
}
.account-info-content {
  padding: 15px 40px;
}

.account-info span {
  font-size: 16px;
  color: var(--main-color);
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}
.account-info input {
  width: 100%;
  height: 100%;
}
.account-info input:focus {
  outline: none;
}
.account-info-edit .user-input {
  height: 34px;
}
.photo-upload-label {
  position: absolute;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-input.input-wrapper.error {
  border: 1px solid red;
}

.img {
  width: 18px;
  height: 18px;
}

.img-account {
  width: auto !important;
  object-fit: unset !important;
}

.photo-remove-label {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.photo-upload-label input {
  position: absolute;
  opacity: 0;
  width: 60px;
  height: 60px;
  z-index: -1;
}
.photo-upload-label svg {
  width: 30px;
  height: 30px;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
  .user-additional, .user-info, .user-role, .user-contacts{
    font-size: 16px;
  }
  .user-contacts svg{
    transform: scale(0.8);
  }
  .user-additional-content,.user-form-btn,.account-info span,.account-info-edit .user-input{
    font-size: 13px;
  }
  .user-pass-text{
    font-size: 18px;
  }
  .user-pass-btn,.user-delete-btn{
    width: 240px;
    font-size: 15px;
  }
  .user-name{
    font-size: 19px;
  }

  .user-description{
    padding: 25px 30px;
  }
  .account-info-edit .user-input{
    height: 27px;
  }
  .user-detail{
    margin-bottom: 5px;
  }

}
/*mobile*/
.popup-overlay.mobile .popup-content{
  min-width: 100%;
  min-height: 250px;
  height: max-content;
}
.popup-overlay.mobile .popup-header{
  font-family: Noto Sans Armenian;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #151515;
}
.popup-overlay.mobile .popup-input-box{
  width: 100%;
}
.popup-overlay.mobile .popup-button-container{
  margin: 20px 0 10px;
}
.popup-overlay.mobile .popup-header{
  margin-bottom: 20px;
}
.account-dashboards-new:has(div):has(.mobile), .user-right:has(div):has(.mobile) {
  flex-shrink: 0!important;
}
.user-right-header.mobile .user-additional{
  font-family: Noto Sans Armenian;
  font-size: 16px!important;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #151515;
}
.user-additional-content.mobile .email-phone{
  margin-top:15px!important;
}

.user-detail-mobile .user-detail-mobile-title{
  font-family: Noto Sans Armenian;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #151515;
  grid-area: a;

}
.user-detail-mobile .user-input{

  padding: 0;
  font-family: Noto Sans Armenian;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;

  min-height: 40px;
  grid-area: b;


}
.user-detail-input-mobile-title{
  font-family: Noto Sans Armenian;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 10px;

}
.user-additional-content.mobile .user-detail-input-change{
  width: 100% !important;
  margin-top: 10px!important;

}
.user-additional-content.mobile .user-input .react-datepicker__input-container input{
  padding-left: 20px;
}
.user-detail-mobile .error_zone{
  margin-top: 10px!important;
}
/*mobile-end*/
@media (min-width: 513px) and (max-width: 1024px) {
  .user-detail-mobile {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-areas:
        "a b"
        "c c";
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .edit + .user-additional-content .user-detail-mobile{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 8px;
    align-items:unset;
    justify-content:unset;
  }
  .user-detail-mobile:not(.underline-none):after {
    content: "";
    width: 100%;
    height: 1px;
    background: #ECECEC;
    grid-area: c;
  }
  .email-phone,.notVerified{
    width: 100%;
  }

  .popup-overlay.mobile .popup-content{
    min-width: 75%;
  }
  .popup-overlay.mobile .popup-input-box{
    width: 90%;
  }
}
@media (max-width: 768px) {
  .user-pass{
    padding: 20px 14px;
    width: max-content;
  }
}
@media (max-height: 600px) {
  .user-right,.user-left{
    min-height: 600px;
  }
}
/*update 16/11/23 */
@media (max-width: 1024px) {
  .user-detail-mobile .user-input {
    display: flex;
    justify-content: flex-end;
  }
  #school-other{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 8px;
    align-items: unset;
    justify-content: unset;
  }
  #school-other .error_zone {
    width: 100% !important;
  }
  .user-additional-content.mobile .user-detail-input-change {
    margin-top: 0!important;
  }
  .user-detail-input-mobile-title{
    width: 100%;
    color: #2c476c;
  }
  #school-other input{
    color: #2c476c;
  }
  #school-other{
    gap: 5px;
  }
  .school-other .error_zone{
    padding: 0px;
  }
}
@media (max-height: 512px) {
  #school-other{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 8px;
    align-items: unset;
    justify-content: unset;
  }
  #school-other .error_zone {
    width: 100% !important;
  }
  .user-additional-content.mobile .user-detail-input-change {
    margin-top: 0!important;
  }
  .user-detail-input-mobile-title{
    width: 100%;
    color: #2c476c;
  }
  #school-other input{
    color: #2c476c;
  }
  #school-other{
    gap: 5px;
  }
  .school-other .error_zone{
    padding: 0px;
  }
}
@media (max-width: 512px) {
  .popup-content {
    min-width: 0px;
  }
  .user-additional-content{
    padding: 0 20px;
  }
  .user-additional-content>div{
    width: 100%;
  }
  #school-other{
    gap: 5px;
  }
  .user-detail-mobile {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 8px;
    align-items:unset;
    justify-content:unset;
  }
  .user-detail-input-mobile-title + div{
    width: 100% !important;
  }
  #school-other .error_zone{
    width: 100% !important;
  }
  .user-detail-input-mobile-title{
    width: 100%;
    color: #2c476c;
  }
  .user-additional-content.mobile .user-detail-input-change{
    margin-top: 0px!important;
  }
  .school-other .error_zone{
    padding: 0px;
  }
  #school-other input{
    color: #2c476c;

  }
}
/*update 16/11/23 end*/
.user_pass_zone form .user-detail .error_zone .error-text{
   margin: 0;
}

.user_pass_zone form .user-detail .error_zone .user-input.input-wrapper input {
  padding: 6px 2px;
}
.user_pass_zone form .user-detail .error_zone .user-input.input-wrapper .profile_password_icon_container {
  top:50%
}
