.paintBar_container {
  position: absolute;
  right: 59px;
  top: 0px;
  height: 100%;
  display: flex;
  justify-content: unset;
  width: fit-content;
}

.pain_items {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  width: 60px;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}


.size_select {
  position: relative;
}
.size_select:hover .paint_size {
  display: flex;
}

.paint_size {
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: space-around;
  position: absolute;
  left: -200px;
  display: none;
}
.paint_size_items {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#distance {
  width: 300px;
  height: fit-content;
  padding: 10px 15px;
  background-color: rgba(81, 77, 77, 0.7);
  color: aliceblue;
  border-radius: 15px;
  font-size: 18px;
  position: absolute;
  right: 100%;
  top: 0;
}

.ruler_modal {
  background-color: #ffffff;
  /* padding: 12px 30px 12px 21px; */
  padding: 13px 31px 10px 20px;
  min-width: 180px;
  width: fit-content;
  height: fit-content;
  z-index: 1;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 7px 26px rgba(44, 71, 108, 0.1);
}

.IconPlane {
  width: 39.79px;
  height: 43.29;
}

.IconCar {
  width: 44.16px;
  height: 42.33px;
}

.IconHorse {
  width: 51px;
  height: 52px;
}

.IconWalk {
  width: 36px;
  height: 54px;
}

.ruler_modal .close_icon {
  display: flex;
  cursor: pointer;
  position: absolute;
  z-index: 999999999;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
}
.ruler_modal img {
  margin: 0;
  width: 100%;
  height: 100%;
}

.ruler_modal p {
  color: #2c476c;
  font-size: 17px;
}
.ruler_modal div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
  padding: 4px 0px;
}
.ruler_modal div span {
  color: #2c476c;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 2s;
}

.ruler_modal div img {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.mapboxgl-popup-tip {
  visibility: hidden !important;
  margin-bottom: 10px;
}

.button_active {
  background-color: #2c476c;
}
.button_active img {
  filter: brightness(0) invert(1);
}



.color_picker_circle {
  width: 24px;
  height: 24px;
  opacity: 1;
  background: conic-gradient(
    from 90deg,
    rgba(102, 70, 232, 1) 25%,
    rgba(86, 177, 68, 1) 49%,
    rgba(203, 197, 68, 1) 75%,
    rgba(232, 67, 61, 1) 100%
  );
}

.color_picker_circle_white_border {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mapboxgl_canvas_painter {
  cursor: url("../../assets/imgs/PaintBar/draw.png") 10 23, auto;
}
.mapboxgl_canvas_eraser {
  /*cursor: url("../../assets/imgs/PaintBar/new-eraser.svg") 20 20, auto;*/
  cursor: url("../../assets/imgs/PaintBar/eraser_mouse.png") 20 20, auto;
}
.mapboxgl_canvas_ruler {
  cursor: url("../../assets/imgs/PaintBar/ruler_mouse.png") 13.9 13.9, auto;
}
.mapboxgl_canvas_commentBox {
  cursor: url("../../assets/imgs/PaintBar/comment-box-cursor-icon.png") 25 25, auto;
}
.mapboxgl_canvas_stickyNotes {
  cursor: url("../../assets/imgs/PaintBar/sticky-notes-cursor.svg") 10 10, auto;
}
.mapboxgl_canvas_textBox {
  cursor: url("../../assets/imgs/PaintBar/text-box-cursor-icon.svg") 25 25, auto;
}

.ruler.mapboxgl-popup.mapboxgl-popup-anchor-top {
  margin-top: -5px !important;
}
.ruler.mapboxgl-popup.mapboxgl-popup-anchor-bottom {
  margin-top: 5px !important;
  z-index: 15;
}

.ruler > .mapboxgl-popup-tip {
  display: none;
}
.ruler_modal p {
  text-align: left;
}

.icon_img {
  width: 26px !important;
  height: auto !important;
  cursor: pointer !important;
}

.ruler_parent{
  width: fit-content;
  height: fit-content;
  /* background-color: #2c476c; */
}

.ruler_modal_arrow{
  width: 15px;
  height: 15px;
  margin: 0 auto;
}

.ruler_modal_top_arrow{
  /* background-color: pink; */
}
.ruler_modal_bottom_arrow{
  /* background-color: green; */
}

.ruler_modal_bottom_arrow:hover ~ .ruler_modal{
  z-index: 15;
  width: fit-content;
  height: fit-content;
  opacity: 1;
  padding: 13px 31px 10px 20px;
  overflow: hidden;
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .landscape_color_container{
    /*background: #fff;*/
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 45px;
    left: 61%;
    max-height: calc((100vh - 150px) / 0.7);
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    transform: translateX(-52%);
    /*zoom: 68%;*/
  }

  .landscape_color_container::-webkit-scrollbar {
      width: 20px;
  }

  .landscape_color_container::-webkit-scrollbar-track {
      background: rgb(131, 58, 180);
      background: linear-gradient(90deg,
          rgba(131, 58, 180, 0) 0%,
          rgba(72, 72, 175, 0) 25%,
          rgba(234, 237, 240, 1) 25%,
          rgba(234, 237, 240, 1) 50%,
          rgba(234, 237, 240, 1) 75%,
          rgba(109, 118, 132, 0) 75%,
          rgba(252, 176, 69, 0) 100%);
  }

  .landscape_color_container::-webkit-scrollbar-thumb {
  background: #2c476c;
    padding: 0 4px;
    /*border-top: 7px solid transparent;*/
    /*border-bottom: 7px solid transparent;*/
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
  }
  .pain_items {
    position: relative;
  }

  .painter_tools_color_item_container{
    position: relative !important;
    top:0 !important;
    left:0 !important;
    margin-top: 10px;
    width: 200px !important;
    padding: 4px !important;
  }

  /*.painter_tools_container_landscape{*/
  /*  width: calc(240px / 0.7) !important;*/
  /*}*/
}

.lastToolboxBtn {
  margin-right: 40px;
}

